import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
// import { useI18n } from 'gatsby-theme-wordpress/src/context/language';

import Layout from '../../components/layout';
import Wrap from '../../components/wrap';
import Content from '../../components/content';
import ContactForm from '../../components/contact-form';
import Addresses from '../../components/addresses';

export const pageQuery = graphql`
  query Contact($id: String) {
    page: wordpressPage(id: { eq: $id }) {
      yoast_meta {
        name
        content
        property
      }
      yoast_title
      yoast_json_ld {
        wordpress__context
        wordpress__graph {
          wordpress__type
          wordpress__id
          name
          description
          width
          height
          inLanguage
          datePublished
          dateModified
          caption
          isPartOf {
            wordpress__id
          }
          primaryImageOfPage {
            wordpress__id
          }
          url
        }
      }

      fancyheader: featured_media {
        ...Fancyheader
      }
      ...Breadcrumbs
      title
      content
      acf {
        form: contact_form
      }
    }
  }
`;

const StyledWrap = styled(Wrap)`
h1, h2 {
  text-align: center;
  font-size: 34px;
  line-height: normal;
  margin-bottom: 30px;
  margin-top: 0;
  &::after {
    content: '';
    display: block;
    height: 3px;
    width: 50px;
    margin: 10px auto 0;
    background-color: rgb(136, 181, 38);
  }
}
`;

const Grid = styled.div`
  @media screen and (min-width: 990px) {
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: 1fr 1fr;
  }
`;

// const FullWidthWrapper = styled.div`
//   padding: 70px 0;
//   background-color: rgb(244, 244, 244);
//   margin-bottom: -70px;
//   ${Wrap} {
//     @media only screen and (max-width: 767px) {
//       padding: 0;
//     }
//   }
// `;

// const MapsWrapper = styled.div`
//   padding: 45px 25px 40px;
//   background-color: white;
// `;

const ContactTemplate = ({ data }) => {
  const { page } = data;
  // const i18n = useI18n();
  return (
    <Layout page={page}>
      <StyledWrap>
        <Grid>
          <div>
            <Content><Addresses /></Content>
          </div>
          <div>
            <Content
              content={page.content}
            />
            {page.acf?.form && <ContactForm small htmlString={page.acf.form} />}
          </div>
        </Grid>
      </StyledWrap>
      {/* <FullWidthWrapper>
        <StyledWrap>
          <MapsWrapper>
            <h2>{i18n?.ourLocation}</h2>
            <iframe
              css="width: 100%; border: 0; height: 480px;"
              title="Google Maps"
              src="https://www.google.com/maps/d/embed?mid=1xNqK0bxiZuNyIIupbSSwSJfmFw8PHS_4&amp;hl=nl"
            />
          </MapsWrapper>
        </StyledWrap>
      </FullWidthWrapper> */}
    </Layout>
  );
};

export default ContactTemplate;
